<template>
	<div class="EquipTypeDetail">
		<!-- <div class="row-vh d-flex flex-row"> -->
		<div class="row-vh d-flex flex-row">
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 유형 코드')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<valid-input
							:vid="'설비 유형 코드'"
							:ref="'equipTypeCode'"
							:inputType="'text'"
							:inputValue.sync="model.equipTypeCode"
							:classList="'form-control'"
							:rules="rules.EQUIP_SYSTEM_TYPE_CODE_RULE"
							:errorMessage="validMessage.EQUIP_SYSTEM_TYPE_CODE_VALID_MESSAGE"
							:disabled="isUpdate"
						></valid-input>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('상위 설비유형')}}</span></div>
					<div class="col-8">
						<!-- <input :ref="'equipTypeCodeName'" class="form-control" v-model="model.equipTypeCodeName" /> -->
						<b-form-select :ref="'upperTypeCode'" v-model="model.upperTypeCode" :options="upperTypeCodeOptions"> </b-form-select>
					</div>
				</div>
			</div>
			<div class="w-50">
				<div class="d-flex flex-row align-items-center mt-3">
					<div class="col-4 text-left d-flex"><span class="h5 mb-0">{{$t('설비 유형명')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
					<div class="col-8">
						<!-- <input :ref="'equipTypeCodeName'" class="form-control" v-model="model.equipTypeCodeName" /> -->
						<valid-input
							:vid="'설비 유형명'"
							:ref="'equipTypeCodeName'"
							:inputType="'text'"
							:inputValue.sync="model.equipTypeCodeName"
							:classList="'form-control'"
							:rules="rules.EQUIP_SYSTEM_TYPE_NAME_RULE"
							:errorMessage="validMessage.EQUIP_SYSTEM_TYPE_NAME_VALID_MESSAGE"
						></valid-input>
					</div>
				</div>

				<!-- <div class="d-flex flex-row align-items-center mt-3">
        <div class="col-3 text-left d-flex">
          <span class="h5 mb-0">설비 도면</span>
        </div>
        <div class="col-8">
          <input :ref="'dsnFilePath'" class="form-control" v-model="model.dsnFilePath" />
        </div>
      </div> -->
				<div class="mt-3">
					<div class="d-flex flex-row align-items-center">
						<div class="col-4 text-left d-flex">
							<span class="h5 mb-0">{{$t('설비 아이콘')}}</span>
						</div>
						<div class="col-8">
							<!-- <input :ref="'iconFilePath'" class="form-control" v-model="model.iconFilePath" /> -->
							<div :style="{ display: 'flex' }">
								<input :ref="'iconFilePath'" class="form-control col-8" v-model="model.iconFilePath" disabled />
								<b-button class="col-4" :style="{ marginLeft: '3px', marginRight: '3px', fontSize: '10px' }" @click="iconFileImport">{{$t('파일 찾기')}}</b-button>
							</div>

							<b-form-file
								:ref="'iconFileImport'"
								class="form-control"
								v-model="tmpIconFile"
								:state="Boolean(tmpIconFile)"
								accept="image/*"
								placeholder=""
								drop-placeholder=""
								@change="onIconFileChange"
								:style="{ display: 'none' }"
							></b-form-file>
						</div>
					</div>
					<div v-if="tmpIconFile === null">
						<a @click="$bvModal.show('previewIcon')" :style="{ color: 'blue' }">{{$t('파일 미리 보기')}}</a>
					</div>

					<b-modal id="previewIcon" size="sm" title="Icon Preview" hide-footer centered>
						<div :style="{ textAlign: 'center' }">
							<b-img v-if="tmpIconFile !== null" :src="tmpIconPath" :style="{ width: '100%' }"></b-img>
							<b-img v-else-if="model.iconFilePath !== ''" :src="xemsHost + '/equip/' + model.iconFilePath" :style="{ width: '100%' }"></b-img>
							<p v-else>{{$t('저장된 이미지가 없습니다.')}}</p>
						</div>
					</b-modal>
					<b-modal id="noneIcon" size="sm" title="Icon Preview" hide-footer centered> </b-modal>
				</div>
			</div>
		</div>

		<div class="w-100">
			<div class="d-flex flex-row align-items-center mt-3">
				<div class="col-2 text-left d-flex">
					<span class="h5 mb-0">{{$t('설비 유형 설명')}}</span>
				</div>
				<div class="col-10">
					<!-- <textarea :ref="'equipTypemDetailDsc'" class="form-control" v-model="model.equipTypemDetailDsc" /> -->
					<valid-textarea
						:vid="'설비 유형 설명'"
						:ref="'equipTypemDetailDsc'"
						:inputType="'textarea'"
						:inputValue.sync="model.equipTypemDetailDsc"
						:classList="'form-control'"
						:rules="rules.EQUIP_SYSTEM_TYPE_DETAIL_RULE"
						:NonStatus="true"
					></valid-textarea>
				</div>
			</div>
		</div>
		<div class="w-50">
			<div class="d-flex flex-row align-items-center mt-3">
				<div class="col-4 text-left"><span class="h5">{{$t('사용여부')}}</span><i class="material-icons ml-1 m-t-1" :style="{ fontSize: '15px' }">check_circle</i></div>
				<div class="col-8">
					<select class="form-control" :ref="'enabled'" v-model="model.enabled">
						<option value="">{{$t('선택')}}</option>
						<option value="Y">{{$t('사용')}}</option>
						<option value="N">{{$t('사용안함')}}</option>
					</select>
				</div>
			</div>
		</div>
		<!-- </div> -->
	</div>
</template>

<style lang="scss" scoped>
	.EquipTypeDetail {
		.flex-row {
			.text-left {
				padding: 0;
			}

			.text-right {
				padding: 0;
			}
		}
	}

	.form-control-none {
		display: block;
		width: 100%;
		height: calc(1.5em + 14px + 2px);
		padding: 7px 0.75rem;
		font-size: 0.75rem;
		font-weight: 400;
		/* line-height: 1.5; */
		color: #2d353c;
		background-color: #fff;
		background-clip: padding-box;
		border: 0px solid #d5dbe0;
		border-radius: 4px;
		-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	}
</style>

<script>
	import backEndApi from "../../../api/backEndApi";
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import AppErrorHandler from "@src/appErrorHandler";

	export default {
		props: ["equipTypeCode", "isUpdate"],
		watch: {},
		data() {
			return {
				model: {
					equipTypeCode: "",
					upperTypeCode: "",
					equipTypeCodeName: "",
					equipTypemDetailDsc: "",
					iconFilePath: "",
					enabled: "",
				},
				originModel: {
					equipTypeCode: "",
					upperTypeCode: "",
					equipTypeCodeName: "",
					equipTypemDetailDsc: "",
					enabled: "",
				},
				upperTypeCodeOptions: [],
				tmpIconFile: null,
				tmpIconPath: "",
				rules,
				validMessage,
			};
		},
		computed: {},
		async created() {
			await this.setModel();
			await this.setUpperTypeCode();
		},
		methods: {
			async setModel() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.equipType.getEquipType(this.equipTypeCode);
					const data = result.data;

					this.model = {
						equipTypeCode: data.equipTypeCode,
						upperTypeCode: data.upperTypeCode,
						equipTypeCodeName: data.equipTypeCodeName,
						equipTypemDetailDsc: data.equipTypemDetailDsc,
						iconFilePath: data.iconFilePath !== "" && data.iconFilePath !== null ? data.iconFilePath.split("equip")[1] : "",
						enabled: data.enabled,
					};
					this.originModel = {
						equipTypeCode: data.equipTypeCode,
						upperTypeCode: data.upperTypeCode,
						equipTypeCodeName: data.equipTypeCodeName,
						equipTypemDetailDsc: data.equipTypemDetailDsc,
						iconFilePath: data.iconFilePath !== "" && data.iconFilePath !== null ? data.iconFilePath.split("equip")[1] : "",
						enabled: data.enabled,
					};
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			/**
			 *  NOTE - 조회 시 상위 설비 유형 api 호출
			 *         상위 설비 유형을 upperTypeCodeList에 저장
			 *         Select Form을 위한 options 정보를 upperTypeCodeOptions에 저장
			 */
			async setUpperTypeCode() {
				// console.log(this.equipTypeCode);

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const result = await backEndApi.equipType.getUpperEquipType(this.equipTypeCode);
					const data = result.data;
					this.upperTypeCodeList = data;

					for (let i = 0; i < data.length; i++) {
						this.upperTypeCodeOptions.push({
							value: data[i].equipTypeCode,
							text: data[i].equipTypeCodeName,
						});
					}
				} catch (err) {
					new AppErrorHandler(err).printErrorLog().errHandling();
				}
			},
			/**
			 * NOTE - 설비 아이콘 이미지 Import
			 * @param {Event} e
			 */
			onIconFileChange(e) {
				// console.log(e);
				if (e.target.files.length === 0) {
					this.tmpIconFile = null;
					this.model.iconFilePath = this.originModel.iconFilePath;
				} else {
					this.tmpIconFile = e.target.files[0];
					this.model.iconFilePath = e.target.files[0].name;
				}

				// this.tmpIconPath = new Blob([this.tmpIconFile], { type: "image/png" });
				// this.tmpIconPath = URL.createObjectURL(this.tmpIconPath);
				// window.URL.revokeObjectURL(this.tmpIconPath);
			},

			iconFileImport() {
				this.$refs.iconFileImport.$refs.input.click();
			},

			async onModify() {
				if (this.isValidation()) {
					let param = {
						equipTypeCode: this.$refs.equipTypeCode.value,
						upperTypeCode: this.$refs.upperTypeCode.value,
						equipTypeCodeName: this.$refs.equipTypeCodeName.value,
						equipTypemDetailDsc: this.$refs.equipTypemDetailDsc.value,
						// iconFilePath: this.$refs.iconFilePath.value,
						iconFilePath: this.tmpIconFile !== null ? this.tmpIconFile : "",
						enabled: this.$refs.enabled.value,
					};

					// 이전 리비전과 소스 에서 아래와 같이 변경함.
					/*           
					↓↓↓↓↓
				*/
					try {
						await backEndApi.equipType.updateEquipType(param);
						this.tmpIconPath = "";
						this.tmpIconFile = null;
						this.alertNoti(popupMessages.COMMON_UPDATE_POPUP_MESSAGE);
						this.$emit("reSearch");
					} catch (err) {
						new AppErrorHandler(err).printErrorLog().errHandling();
					}
				}
			},
			isValidation() {
				var flag = true;

				if (!this.$refs.equipTypeCode.value) {
					this.$refs.equipTypeCode.focus();
					this.alertNoti(popupMessages.EQUIP_CODE_VALID_POPUP_MESSAGE);
					flag = false;
				} else if (!this.$refs.equipTypeCodeName.value) {
					this.$refs.equipTypeCodeName.focus();
					this.alertNoti(popupMessages.EQUIP_NAME_VALID_POPUP_MESSAGE);
					flag = false;
				} else if (!this.$refs.enabled.value) {
					this.$refs.enabled.focus();
					this.alertNoti(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
					flag = false;
				}

				return flag;
			},
			async onRemove() {
				// console.log("Grid detail : onRemove");

				// let param = {
				//   equipTypeCode : this.$refs.equipTypeCode.value,
				// }

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
				↓↓↓↓↓
			*/
				try {
					const popupResult = await this.alertConfirmWarning(popupMessages.COMMON_DELETE_CONFIRM_POPUP_MESSAGE);
					if (!popupResult.value) return;

					await backEndApi.equipType.deleteEquipType(this.$refs.equipTypeCode.value);
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					this.$emit("reSearch");
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			onReset() {
				this.model = {
					equipTypeCode: this.originModel.equipTypeCode,
					upperTypeCode: this.originModel.upperTypeCode,
					equipTypeCodeName: this.originModel.equipTypeCodeName,
					equipTypemDetailDsc: this.originModel.equipTypemDetailDsc,
					iconFilePath: this.originModel.iconFilePath,
					enabled: this.originModel.enabled,
				};
			},
		},
	};
</script>
